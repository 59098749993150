<template>
  <div class="sub-container flex-sb">
    <div class="content col" id="application-detail">
      <section class="section">
        <h3 class="section-title">신청 상세정보</h3>
        <table class="tbl-view app-detail-info">
          <colgroup>
            <col class="head" />
            <col class="data" />
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
            <tr>
              <th>신청일</th>
              <td>{{ convertDateFromat(application.createdAt) }}</td>
              <th>신청항목</th>
              <td>{{ application.applicationItem }}</td>
            </tr>
            <tr>
              <th>이름</th>
              <td>{{ application.name }} ({{ application.applicationRegistrationType }})</td>
              <th class="multi-line">주민번호<br />(외국인번호)</th>
              <td>
                {{ application.socialSecurityNumber }}
                <span class="bar"></span>
                {{ application.nationality }} {{ application.sex }}
              </td>
            </tr>
            <tr>
              <th>휴대폰번호</th>
              <td>{{ application.mobile }}</td>
              <th>이메일</th>
              <td>{{ application.email }}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="3">
                {{ application.address1 }} {{ application.address2 }} ({{
                  application.postcode
                }})
              </td>
            </tr>
            <tr>
              <th class="multi-line">보호자<br />(연락처)</th>
              <td>
                {{ application.protectorName }}
                <span class="bar"></span>
                {{ application.protectorMobile }}
              </td>
              <th>신청자와 관계</th>
              <td>{{ application.protectorRelationship }}</td>
            </tr>
            <tr>
              <th>보험유형</th>
              <td>
                <span class="insurance">{{
                  application.healthInsuranceType
                }}</span>
              </td>
              <th>가족수 | 보험료</th>
              <td>
                {{ application.familyCount }}인
                <span class="bar"></span>
                <comma-label :value="application.premium"></comma-label>원
              </td>
            </tr>
            <tr>
              <th>실비보험 소지여부</th>
              <td colspan="3">{{ application.lifeInsuranceExist }}</td>
            </tr>
            <tr>
              <th>치료내용</th>
              <td colspan="3">{{ application.treatmentDetails }}</td>
            </tr>

            <tr>
              <th>총비용</th>
              <td><comma-label :value="application.totalAmount"></comma-label>원</td>
              <th>지원비용</th>
              <td><comma-label :value="application.supportAmount"></comma-label>원</td>
            </tr>
            <tr>
              <th>자기부담금</th>
              <td colspan="3"><comma-label :value="application.selfAmount"></comma-label>원</td>

            </tr>
            <tr>
              <th>병원측 메모</th>
              <td colspan="3">{{ application.hospitalMemo }}</td>

            </tr>
            <tr
              v-if="application.document != undefined"
              data-html2canvas-ignore="true"
            >
              <th>첨부서류</th>
              <td colspan="3">
                <div class="attach-file">
                  <div class="attach-file-sub">
                    <span class="file-icon"  v-show="application.document.residentRegister != null" @click="downloadDocument(application.document.residentRegister,application.name +'_주민등록등본')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.residentRegister != null"

                      @click.prevent="
                        showDocumentItem(application.document.residentRegister)
                      "
                      ><span class="txt">주민등록등본</span></a
                    >
                    <span class="file-icon"  v-show="application.document.healthInsurancePayments != null" @click="downloadDocument(application.document.healthInsurancePayments,application.name +'_건강보험납부확인서')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.healthInsurancePayments != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.healthInsurancePayments
                        )
                      "
                      ><span class="txt">건강보험납부확인서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.insurerResidentRegister != null" @click="downloadDocument(application.document.insurerResidentRegister,application.name +'_보험자주민등록등본')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.insurerResidentRegister != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.insurerResidentRegister
                        )
                      "
                      ><span class="txt">보험자주민등록등본</span></a
                    >
                    <span class="file-icon"  v-show="application.document.familyRelationsCertificate != null" @click="downloadDocument(application.document.familyRelationsCertificate,application.name +'_가족관계증명서')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.familyRelationsCertificate != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.familyRelationsCertificate
                        )
                      "
                      ><span class="txt">가족관계증명서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.healthInsuranceCertificate != null" @click="downloadDocument(application.document.healthInsuranceCertificate,application.name +'_건강보험보험자격확인서')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.healthInsuranceCertificate != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.healthInsuranceCertificate
                        )
                      "
                      ><span class="txt">건강보험보험자격확인서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.oldAgePensionerCertificate != null" @click="downloadDocument(application.document.oldAgePensionerCertificate,application.name +'_기초연금수급자확인서')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.oldAgePensionerCertificate != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.oldAgePensionerCertificate
                        )
                      "
                      ><span class="txt">기초연금수급자확인서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.basicLivelihoodCertificate != null" @click="downloadDocument(application.document.basicLivelihoodCertificate,application.name +'_기초생활수급자확인서')" ></span>
                    <a
                      class="btn-down-file"
                      v-show="application.document.basicLivelihoodCertificate != null"
                      @click.prevent="
                        showDocumentItem(
                          application.document.basicLivelihoodCertificate
                        )
                      "
                      ><span class="txt">기초생활수급자확인서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.secondClassCertificate != null" @click="downloadDocument(application.document.secondClassCertificate,application.name +'_차상위계층증명서')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.secondClassCertificate != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.secondClassCertificate
                        )
                      "
                    ><span class="txt">차상위계층증명서</span></a
                    >
                    <span class="file-icon"  v-show="application.document.passport != null" @click="downloadDocument(application.document.passport,application.name +'_여권')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.passport != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.passport
                        )
                      "
                    ><span class="txt">여권</span></a
                    >
                    <span class="file-icon"  v-show="application.document.alienRegistrationCertificate != null" @click="downloadDocument(application.document.alienRegistrationCertificate,application.name +'_외국인등록사실증명')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.alienRegistrationCertificate != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.alienRegistrationCertificate
                        )
                      "
                    ><span class="txt">외국인등록사실증명</span></a
                    >
                    <span class="file-icon"  v-show="application.document.incomeAmountCertificate != null" @click="downloadDocument(application.document.incomeAmountCertificate,application.name +'_소득금액증명원')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.incomeAmountCertificate != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.incomeAmountCertificate
                        )
                      "
                    ><span class="txt">소득금액증명원</span></a
                    >
                    <span class="file-icon"  v-show="application.document.etc != null" @click="downloadDocument(application.document.etc,application.name +'_추가서류')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.etc != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.etc
                        )
                      "
                    ><span class="txt">추가서류</span></a
                    >
                    <span class="file-icon"  v-show="application.document.alienRegistrationCard != null" @click="downloadDocument(application.document.alienRegistrationCard,application.name +'_외국인등록증')" ></span>
                    <a
                        class="btn-down-file"
                        v-show="application.document.alienRegistrationCard != null"
                        @click.prevent="
                        showDocumentItem(
                          application.document.alienRegistrationCard
                        )
                      "
                    ><span class="txt">외국인등록증</span></a
                    >
                    <span class="btn-down-file" >
                      <span class="file-icon"  v-show="application.document.recommendationLetter != null" @click="downloadDocument(application.document.recommendationLetter,application.name +'_기관추천서')" ></span>

<!--                      <a v-show="application.document.recommendationLetter != null" class="txt" @click="downloadDocument(application.document.recommendationLetter,'기관추천서_' + application.nabla)"></a>-->
                      <a v-show="application.document.recommendationLetter != null"
                         @click.prevent="
                        showDocumentItem(
                          application.document.recommendationLetter
                        )
                      "
                      ><span class="txt2">기관추천서</span></a>
                    </span>


                  </div>
                  <div class="attach-file-sub">
                    <button
                      class="btn-down-all"
                      @click.prevent="
                        downloadItemAll()
                      "
                    >
                      <span class="txt">전체 다운로드</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="application.hospital != undefined">
              <th class="multi-line">신청병원<br />진료과목</th>
              <td>
                {{ application.hospital.hospitalName }}
                <span class="bar"></span>
                {{ application.treatmentSubject }}
              </td>
              <th>지원항목</th>
              <td>{{ application.medicalCare }}</td>
            </tr>
            <tr>
              <th>생활환경 및 사연</th>
              <td colspan="3">{{ application.story }}</td>
            </tr>
          </tbody>
        </table>
        <div class="table-detail-foot flex-sb">
          <button
            class="btn-outline tf-btn"
            @click="makePDF"
            data-html2canvas-ignore="true"
          >
            <span class="txt ico down-sb">신청서 다운로드</span>
          </button>
          <button
            class="btn-confirm tf-btn"
            @click="openApplicationEdit"
            data-html2canvas-ignore="true"
          >
            <span class="txt ico apply-w">신청서 수정하기</span>
          </button>
        </div>
      </section>

      <section class="section">
        <h3 class="section-title">상태변경</h3>
        <table class="tbl-view chg-cd">
          <colgroup>
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
            <tr>
              <th>진행상태</th>
              <td>
                <div class="t-radio-group" style="width:700px;">
                  <div class="radio">
                    <input
                      type="radio"
                      id="radio01"
                      name="radio"
                      v-model="status"
                      value="received"
                    />
                    <label for="radio01">접수</label>
                  </div>
                  <span class="bar"></span>
                  <div class="radio">
                    <input
                      type="radio"
                      id="radio02"
                      name="radio"
                      v-model="status"
                      value="selected"
                    />
                    <label for="radio02">선정</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      id="radio03"
                      name="radio"
                      v-model="status"
                      value="recheck"
                    />
                    <label for="radio03">재확인</label>
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      id="radio04"
                      name="radio"
                      v-model="status"
                      value="review"
                    />
                    <label for="radio04">심의</label>
                  </div>
                  <span class="bar"></span>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio012"
                        name="radio"
                        v-model="status"
                        value="first_examination"
                    />
                    <label for="radio012">초진완료</label>
                  </div>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio07"
                        name="radio"
                        v-model="status"
                        value="treatment_progress"
                    />
                    <label for="radio07">치료진행</label>
                  </div>
                  <span class="bar"></span>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio11"
                        name="radio"
                        v-model="status"
                        value="examination_completed"
                    />
                    <label for="radio11">검진완료</label>
                  </div>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio08"
                        name="radio"
                        v-model="status"
                        value="treatment_completed"
                    />
                    <label for="radio08">치료완료</label>
                  </div>
                </div>
                <div class="t-radio-group" style="width:450px;margin-top: 10px;">
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio09"
                        name="radio"
                        v-model="status"
                        value="settlement_waiting"
                    />
                    <label for="radio09">정산대기</label>
                  </div>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio10"
                        name="radio"
                        v-model="status"
                        value="settlement_completed"
                    />
                    <label for="radio10">정산완료</label>
                  </div>
                  <span class="bar"></span>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio06"
                        name="radio"
                        v-model="status"
                        value="cancel"
                    />
                    <label for="radio06">취소</label>
                  </div>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio12"
                        name="radio"
                        v-model="status"
                        value="timeout"
                    />
                    <label for="radio12">기간만료</label>
                  </div>
                  <div class="radio">
                    <input
                        type="radio"
                        id="radio05"
                        name="radio"
                        v-model="status"
                        value="drop"
                    />
                    <label for="radio05">탈락</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>진행상태 내용</th>
              <td>
                <div
                  class="memo-wrap"
                  v-for="statusItem in application.statusList"
                  v-bind:key="statusItem.applicationStatusContentsId"
                >
                  <div class="date-writer">
                    <p class="txt">{{ statusItem.createdAt.replace("T"," ").substring(0,19)  }} <span class="memo">[{{ statusMap[statusItem.status] }}]</span></p>
                    <p class="txt">입력자 : {{ statusItem.adminName || statusItem.hospitalName }}</p>
                  </div>
                  <div class="memo" style="word-break:break-all;">
                    <p v-html="handleNewLine(statusItem.contents)" ></p>
                  </div>
                </div>
                <div class="add-memo">
                  <textarea
                      style="height:100px;"
                    class="add-memo-inp inp-txt-common"
                    :placeholder="'[' + statusMap[status] + ']'+ ' 내용을 입력해주세요.'"
                    v-model="statusContents"
                    data-html2canvas-ignore="true"
                  />
                  <button
                    class="btn-outline write"
                    data-html2canvas-ignore="true"
                    @click="updateStatus"
                  >
                    <span class="txt">쓰기</span>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <div
                  class="memo-wrap"
                  v-for="applicationMemo in application.memoList"
                  v-bind:key="applicationMemo.applicationMemoId"
                >
                  <div class="date-writer">
                    <p class="txt" style="word-break:break-all;">{{ applicationMemo.createdAt.replace("T"," ").substring(0,19) }}</p>
                    <p class="txt">입력자 : {{ applicationMemo.adminName || applicationMemo.hospitalName}}</p>
                  </div>
                  <div class="memo">
                    <p v-html="handleNewLine(applicationMemo.memo)" ></p>
                  </div>
                </div>
                <div class="add-memo">
                  <textarea
                      style="height:100px;"
                    class="add-memo-inp inp-txt-common"
                    placeholder="메모를 입력해주세요."
                    v-model="memoContents"
                    data-html2canvas-ignore="true"
                  />
                  <button
                    class="btn-outline write"
                    @click="updateMemo"
                    data-html2canvas-ignore="true"
                  >
                    <span class="txt">쓰기</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="view-tail" data-html2canvas-ignore="true">
          <button class="btn-go-list" @click="goBack">
            <span class="txt go-list">목록으로</span>
          </button>
        </div>
      </section>
    </div>
    <div class="content col">
      <other-application
        ref="other-application"
        :application-id="application.applicationId"
        :name="application.name"
      ></other-application>
      <member-qna ref="member-qna" :member-id="application.memberId" :name="application.name"  :parent-now-page="1"></member-qna>
    </div>
  </div>
</template>
<style>
  .btn-down-file {
    cursor: pointer;
  }
</style>
<script>
import OtherApplication from "../components/OtherApplication";
import MemberQna from "../components/MemberQna";
import JSZip from "jszip"
import JSZipUtils from "jszip-utils"
import html2canvas from "html2canvas";
import 'viewerjs/dist/viewer.css'
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
// eslint-disable-next-line no-unused-vars
import FileDownload from "js-file-download";
import CommaLabel from "@/components/CommaLabel.vue";
export default {
  name: "ApplicationDetail",
  components: {CommaLabel, OtherApplication, MemberQna },
  data: () => ({
    application: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    statusChangeMemo: "",
    propTitle: "mypdf",
    statusMap: {
      received: "접수",
      selected: "선정",
      recheck: "재확인",
      review: "심의",
      first_examination: "초진완료",
      treatment_progress: "치료진행",
      examination_completed: "검진완료",
      treatment_completed: "치료완료",
      settlement_waiting: "정산대기",
      settlement_completed: "정산완료",
      drop: "탈락",
      timeout: "기간만료",
      cancel: "취소",
    }
  }),
  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.params.applicationId);
    setTimeout(() => {
      if (this.application.socialSecurityNumber != null) {
        this.application.nationality = "내국인";
        const socialSecurityNumberArray = this.application.socialSecurityNumber.split("-");
        if (socialSecurityNumberArray[1].substring(0,1) == '1') {
          this.application.sex = "남성";
        } else if (socialSecurityNumberArray[1].substring(0,1) == '2') {
          this.application.sex = "여성";
        }

      }
    }, 1000);
  },
  beforeMount() {
    this.getApplication();
  },

  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g,"</br>");
    },
    async getApplication() {
      // console.log("==================================================");
      // console.log(this.$route.path);
      // console.log("==================================================");
      const getApplicationPromise = await this.$axios.get(
        "application/" + this.$route.params.applicationId
      );
      const [response] = await Promise.all([getApplicationPromise]);
      this.application = response.data.data;
      console.log(this.application);
      this.$refs["member-qna"].list(this.application.memberId);
      this.status = this.application.status;
      this.$refs["other-application"].list(this.application.applicationId);
    },
    openApplicationEdit() {
      this.$router.push({
        path: "/application/edit/" + this.$route.params.applicationId
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    downloadDocument(url,filename) {
      console.log(url);
      console.log(filename);
      if (url.indexOf("|") >= 0) {
        let urlArray = url.split("|");
        for(let i=0;i<urlArray.length;i++){
          this.downloadFile(urlArray[i],filename);
        }
      }  else {
        this.downloadFile(url,filename);
      }

    },
    downloadFile(url,filename) {
      console.log(url);
      console.log(filename);
      url = url.replace("https://files.bori.app/","file/")
      // window.open(url, '_blank').focus();
      const FileDownload = require('js-file-download');
      this.$axios.get(url, {
        responseType: "blob"
      }).then(response => {
        FileDownload(response.data, filename +"." + url.split('.').pop());
      }).catch(() => {
        alert("파일 다운로드 실패");
      });
    },
    showDocumentItem(url) {
      if (url == "" || url == null ) {
        alert("서류가 없습니다.");
        return;
      }

      if (url.indexOf("pdf") >= 0) {
        alert("pdf 서류 입니다. 아이콘을 클릭해주세요.");
        return;
      }
      var images = [];
      if (url.indexOf("|") >= 0) {
        images = url.split("|");
      } else {
        images = [url];
      }
      console.log(images);
      this.$viewerApi({
        images: images,
      })

    },
    downloadItemAll() {

      // this.application.document.





      var zip = new JSZip();
      var count = 0;
      var zipFilename = "서류모음.zip";
      var urls = [];

      for (const [key, value] of Object.entries(this.application.document)) {
        console.log(key,value);
        if (value != null && value != "" && key != "applicationDocumentId") {
          if (value.indexOf("|") >= 0) {
            var valueArray = value.split("|");
            valueArray.forEach( valueItem => {
              urls.push(valueItem);
            })
          } else {
            urls.push(value);
          }
        }
      }


      urls.forEach(function(url){
        var filename = "filename";
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if(err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, {binary:true});
          count++;
          if (count == urls.length) {
            zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);
            });
          }
        });
      });

    },
    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    async updateStatus() {
      if (window.confirm("진행상태 변경 확인\n진행 상태를 [" + this.statusMap[this.status] + "]으로 변경하시겠습니까?")) {
        var param = {
          applicationId: this.$route.params.applicationId,
          status: this.status,
          contents: this.statusContents
        };
        this.$axios
            .post("application/status", param)
            .then(response => {
              if (response.data.success) {
                this.$alert.show(response.data.message);
                // this.$router.go(0);
                this.getApplication();
                this.statusContents = "";
              } else {
                this.$alert.show(response.data.message);
              }
            })
            .catch(ex => {
              if (ex.response.data.message.constructor == Object) {
                this.$alert.show(ex.response.data.message);
              }
            });
      }
    },
    async updateMemo() {
      var param = {
        applicationId: this.$route.params.applicationId,
        memo: this.memoContents
      };
      this.$axios
        .post("application/memo", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getApplication();
            this.memoContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    makePDF() {
      this.$router.push({name: 'ApplicationPdf', params: this.application})

    },
    makePDF2() {
      console.log("makePDF");
      window.html2canvas = html2canvas; //Vue.js 특성상 window 객체에 직접 할당해야한다.
      let that = this;
      let pdf = new jsPDF("p", "mm", "a4");
      let canvas = pdf.canvas;
      const pageWidth = 210; //캔버스 너비 mm
      // const pageHeight = 295 //캔버스 높이 mm
      canvas.width = pageWidth;

      let ele = document.querySelector("#application-detail");
      let width = ele.offsetWidth; // 셀렉트한 요소의 px 너비
      let height = ele.offsetHeight; // 셀렉트한 요소의 px 높이
      console.log(width + "___" + height);
      let imgHeight = (pageWidth * height) / width; // 이미지 높이값 px to mm 변환
      //let imgWidth = pageHeight * width / height;
      if (!ele) {
        console.warn("#application-detail" + " is not exist.");
        return false;
      }
      console.log("makePDF2");
      console.log(ele);
      html2canvas(ele).then(function(canvas) {
        console.log("makePDF3");
        let position = 0;
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(
          imgData,
          "png",
          0,
          position,
          pageWidth,
          imgHeight,
          undefined,
          "slow"
        );

        // //Paging 처리
        // let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
        // heightLeft -= pageHeight
        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight
        //   pdf.addPage();
        //   pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
        //   heightLeft -= pageHeight
        // }

        pdf.save(
          that.application.name +
            "_" +
            that.application.applicationItem +
            ".pdf"
        );
      });
      console.log("end");
      console.log(pdf);
    }
  }
};
</script>
